import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { jsx, Button, Text, Link as TLink } from "theme-ui";
import { Link } from "gatsby";
import bryce from "../../../../static/bp_headshot.jpg";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <img src={bryce} style={{
      "maxWidth": "200px",
      "borderRadius": "15%"
    }} alt="Avatar" />
    <div><sub><sup>scroll down to see my latest posts</sup></sub></div>
    <h1>{`Hey there 👋`}</h1>
    <h3>{`I'm Bryce.`}</h3>
    <p>{`Master of Computer Science, chemex connoisseur, amateur slackliner, eater of tacos, the Father of Plants.`}</p>
    <Button as={Link} to={'/about'} mdxType="Button">
  Learn more about me →
    </Button>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      